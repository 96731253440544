<template>

    <v-container height="100%" fluid :class="{'grey lighten-2': !$vuetify.theme.dark}">

        <!-- HEIGHT FILLER -->
        <v-row class="primary" style="height: 400px;">
        </v-row>

        <!-- Content Card -->
        <v-row justify="center" :class="{'grey lighten-2': !$vuetify.theme.dark}">

            <v-col cols="12" md="8" lg="6" xl="5" class="py-0">

                <v-fade-transition>
                    <v-card v-show="loaded" class="mx-sm-4 mx-0" style="top: -200px">

                        <v-container>

                            <v-list two-line class="pt-0">
                                <v-list-item class="my-n2">
                                    <div class="subtitle-1 font-weight-medium">AKUN BANK</div>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-icon>mdi-account</v-icon>

                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>{{ identity.preferred_username }}</v-list-item-title>
                                        <v-list-item-subtitle>OCBC 3452 5647 9812 3816</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-avatar>
                                        <!--<v-icon>mdi-currency-usd</v-icon>-->
                                        <div style="padding-left: 6px">IDR</div>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>6,000,000</v-list-item-title>
                                        <v-list-item-subtitle>Balance</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-divider></v-divider>

                                <v-list-item>
                                    <div class="subtitle-1 font-weight-medium">Transfer uang</div>
                                </v-list-item>

                                <v-form ref="transferDetails">

                                    <v-list-item>
                                        <v-text-field
                                            v-model="receiverName"
                                            :disabled="creatingTransaction"
                                            label="Penerima"
                                            :rules="requiredRules"
                                        ></v-text-field>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-text-field
                                            v-model="receiverNumber"
                                            :disabled="creatingTransaction"
                                            label="Nomor rekening"
                                            :rules="requiredRules"
                                        ></v-text-field>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <div style="padding-left: 6px">IDR</div>
                                        </v-list-item-avatar>
                                        <v-text-field
                                            v-model.number="amount"
                                            :disabled="creatingTransaction"
                                            :rules="requiredRules"
                                            @keypress="onlyNumber"
                                        ></v-text-field>
                                    </v-list-item>

                                </v-form>

                            </v-list>

                            <v-row no-gutters class="mx-2 mb-2">
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    :block="$vuetify.breakpoint.mobile"
                                    class="white--text"
                                    @click="transferMoney"
                                    :disabled="creatingTransaction"
                                >
                                    <v-progress-circular
                                        v-show="creatingTransaction"
                                        size="20"
                                        class="mr-1"
                                        indeterminate
                                        color="primary"
                                    ></v-progress-circular>
                                    Transfer</v-btn>
                            </v-row>


                        </v-container>

                        <transfer-money-dialog
                            v-if="transferOngoing"
                            :account-number="receiverNumber"
                            :amount="amount"
                            :receiver="receiverName"
                            :transactionStatus="transactionStatus"
                            @close="onTransferDialogClosed"
                            @restart="transferMoney"
                        ></transfer-money-dialog>

                    </v-card>
                </v-fade-transition>

            </v-col>

        </v-row>

    </v-container>

</template>

<script>
import {IdentityAPI} from "@/utils/identity";
import TransferMoneyDialog from "@/components/TransferMoneyDialog";
import {TransactionApi} from "@/utils/transaction";

export default {
    name: "BankingPage",

    components: {TransferMoneyDialog},

    data: () => ({
        amount: 3500,
        creatingTransaction: false,
        identity: {},
        loaded: false,
        receiverName: "Panggih Sudarsono",
        receiverNumber: "6543 7611 3489 6372",
        requiredRules: [
            v => !!v || "This field is required",
        ],
        timer: null,
        transferOngoing: false,
        transactionStatus: 0,
    }),

    methods: {

        /**
         * Modified version of https://stackoverflow.com/questions/39782176/
         * Allow only numbers and comma character
         */
        onlyNumber(event) {
            let charCode = event.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 44) {
                event.preventDefault();
            } else {
                return true;
            }
        },

        onTransferDialogClosed() {
            this.transferOngoing = false;
            if (this.timer) {
                clearTimeout(this.timer);
            }
        },

        transferMoney() {
            if (!this.$refs.transferDetails.validate()) {
                return;
            }

            this.transactionStatus = TransactionApi.STATUS_CODES.CREATED;
            this.creatingTransaction = true;

            let title =`Bayar ₹ ${this.amount} sampai ${this.receiverName}`;
            TransactionApi.pay(title)
                .then((response) => {
                    this.creatingTransaction = false;
                    let transactionUUID = response.uuid;
                    this.checkTransactionStatus(transactionUUID);
                    this.transferOngoing = true;
                })
                .catch((error) => {
                    this.creatingTransaction = false;
                    console.log(error);
                })
        },

        async checkTransactionStatus(transactionUUID) {
            let pollFunction = async () => {
                let response = await TransactionApi.checkStatus(transactionUUID);
                this.transactionStatus = response.status;

                switch (this.transactionStatus) {
                    case TransactionApi.STATUS_CODES.DENIED:
                    case TransactionApi.STATUS_CODES.ERROR:
                    case TransactionApi.STATUS_CODES.EXPIRED:
                    case TransactionApi.STATUS_CODES.SUCCESS:
                        clearTimeout(this.timer);
                        return;
                }

                this.timer = setTimeout(pollFunction, 2000);

            };

            await pollFunction();
        },

    },


    beforeDestroy() {
        if (this.timer != null) {
            clearTimeout(this.timer);
        }
    },

    created() {
        this.identity = IdentityAPI.identity;
        this.loaded = true;
    }
}
</script>

<style scoped>

</style>
