import Utils from "@/utils/utils";
import axios from "axios";
import {StorageManager} from "@/utils/storage";
import {IdentityAPI} from "@/utils/identity";

let oauthCommunicator = axios.create({
    baseURL: config.OAUTH_API_URL,
    timeout: 5000,
    withCredentials: true
});

const redirectURL = config.BASE_URL + "authorize";

export class StrongpinOAuth {

    static accessToken = ""

    static INSECURE_REFRESH_TOKEN_ATTRIBUTE = "INSECURE_REFRESH_TOKEN_ATTRIBUTE";

    static clear() {
        this.accessToken = "";
        StorageManager.delete(this.INSECURE_REFRESH_TOKEN_ATTRIBUTE);
    }

    static async isAuthenticated() {
        if (this.accessToken !== "") return true;

        try {
            await this.exchangeRefreshToken();
        } catch (e) {
            return false;
        }

        return true;
    }

    static start() {
        let state = Utils.generateRandom(32);

        let oauthURL = new URL(config.OAUTH_API_URL + "authorize");
        oauthURL.searchParams.append("redirect_uri", redirectURL);
        oauthURL.searchParams.append("response_type", "code");
        oauthURL.searchParams.append("client_id", config.OAUTH_CLIENT_ID);
        oauthURL.searchParams.append("scope", "openid");
        oauthURL.searchParams.append("state", state);

        window.location.replace(oauthURL);
    }

    static async handleAuthorizationResponse(authorizationCode, state) {
        // TODO: maybe add verification of state parameter

        try {
            await this.exchangeAuthorizationCode(authorizationCode);
        } catch(error) {
            console.log("Could not exchange authorization code for access token");
            console.error(error);
            throw error;
        }

    }

    static async exchangeAuthorizationCode(authorizationCode) {
        let data = "grant_type=authorization_code" +
            "&code=" + authorizationCode +
            "&redirect_uri=" + redirectURL;

        let credentials = btoa(config.OAUTH_CLIENT_ID + ":" + config.OAUTH_CLIENT_SECRET);

        let headers = {
            "Authorization": "Basic " + credentials,
            "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
        }

        let response = await oauthCommunicator.post("token/", data, {headers: headers});

        if (response.status > 299) {
            throw "Could not exchange authorization code for access token";
        }

        let refreshToken = {
            token: response.data["refresh_token"]
        }

        StorageManager.set(this.INSECURE_REFRESH_TOKEN_ATTRIBUTE, refreshToken);

        this.accessToken = response.data["access_token"];

        await IdentityAPI.getIdentity();
    }

    static async exchangeRefreshToken() {
        let tokenObject;

        try {
            tokenObject = StorageManager.get(this.INSECURE_REFRESH_TOKEN_ATTRIBUTE);
        } catch(e) {
            throw new Error("No refresh token available");
        }

        let data = "grant_type=refresh_token" +
            "&code=" + tokenObject.token +
            "&redirect_uri=" + redirectURL;

        let credentials = btoa(config.OAUTH_CLIENT_ID + ":" + config.OAUTH_CLIENT_SECRET);

        let headers = {
            "Authorization": "Basic " + credentials,
            "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
        }

        let response = await oauthCommunicator.post("token/", data, {headers: headers});

        if (response.status > 299) {
            throw new Error("Could not exchange refresh token for access token");
        }

        let newRefreshToken = {
            token: response.data["refresh_token"]
        }

        StorageManager.set(this.INSECURE_REFRESH_TOKEN_ATTRIBUTE, newRefreshToken);

        this.accessToken = response.data["access_token"];

        await IdentityAPI.getIdentity();
    }

}