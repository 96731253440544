<template>

  <v-container :class="$vuetify.breakpoint.smAndDown?'small':''" class="fill-height" fluid
               height="100%"
  >
    <!-- Content Card -->
    <v-row class="fill-height pt-md-4" justify="center">

      <!-- How to sign card -->
      <v-col class="py-0" cols="12" lg="3" md="4" style="max-height: 100%">
        <v-fade-transition>
          <v-card
              class="pa-3 mb-4 howToSignCard"
              elevation="0"
          >
            <template v-if="!signedPDF">

              <v-card-title class="py-0 pr-0 pl-1 stepperTitle">
                {{ $t('home.instructions.header') }}
                <v-spacer/>
              </v-card-title>
              <v-card-text class="text-body-1">
                <v-stepper v-model="signStepper" class="mx-n2" flat vertical>
                  <v-stepper-step
                      step="1">
                    {{ $t('home.instructions.select_document') }}
                  </v-stepper-step>

                  <v-stepper-step
                      step="2">
                    {{ $t('home.instructions.choose_location') }}
                  </v-stepper-step>

                  <v-stepper-step
                      step="3">
                    {{ $t('home.instructions.sign_with_app') }}
                  </v-stepper-step>
                </v-stepper>

              </v-card-text>
              <v-card-actions class="pa-1">
                <v-row no-gutters>
                  <v-btn :disabled="submittingFile || signStepper < 2" class="text-none" color="primary" outlined
                         text @click="onBackClicked">
                    {{ $t('common.back') }}
                  </v-btn>
                  <v-spacer/>
                  <v-btn :disabled="submittingFile || signStepper < 3" class="ml-2 text-none" color="primary"
                         elevation="0"
                         @click="onConfirmSignatureClicked">
                    <v-progress-circular
                        v-show="submittingFile"
                        class="mr-1"
                        color="primary"
                        indeterminate
                        size="20"
                    ></v-progress-circular>
                    {{ $t('home.instructions.sign') }}
                  </v-btn>
                </v-row>
              </v-card-actions>
            </template>

            <template v-else>
              <v-card-title class="py-0 pr-0">
                {{ $t('home.instructions.content_ready') }}
              </v-card-title>
              <v-card-text class="text-body-1 pb-0 px-4">
                {{ $t('home.instructions.signed_as') }}
                <IdentityCard class="mt-4"/>
                {{ $t('home.instructions.content_ready_detail') }}
              </v-card-text>
              <v-card-actions class="flex-row-reverse mt-2">
                <v-btn class="white--text" color="primary" style="border-radius: 8px" @click="onDownloadClicked">
                  <v-icon class="mr-2">mdi-tray-arrow-down</v-icon>
                  {{ $t('home.instructions.download_file') }}
                </v-btn>
              </v-card-actions>
            </template>
          </v-card>
        </v-fade-transition>

      </v-col>

      <v-col class="py-0 mb-5" cols="12" lg="6" md="8" style="min-height: 40%" xl="5">

        <!-- Select file card -->
        <v-fade-transition>
          <v-card v-show="loaded && showPDFSelector" id="selectFileCard"
                  class="pa-4 mx-0 mb-8 fill-height"
                  elevation="0">

            <v-container class="d-flex" style="border-style: dashed; height: 100%; border-color: gray">

              <v-row align="center" class="flex-column" justify="center" no-gutters>

                <v-icon v-if="!$vuetify.breakpoint.smAndDown" color="#43474E" size="60">mdi-tray-arrow-up</v-icon>
                <p v-if="!$vuetify.breakpoint.smAndDown" class="dragAndDropMessage">
                  Drag and drop your PDF file here
                  <br/>
                  <br/>
                  - OR -
                </p>

                <!-- Button that is used to select a file -->
                <v-btn
                    :loading="isSelectingFile"
                    class="text-none browseFilesButton"
                    color="primary"
                    type="input"
                    @click="onSelectFileClicked"
                > {{ $t('common.select_file') }}
                </v-btn>

                <input
                    ref="hiddenFileUploader"
                    accept="application/pdf"
                    style="position: absolute; height: 100%; width: 100%; opacity: 0"
                    type="file"
                    @change="onFileChanged"
                    @click.prevent
                >

              </v-row>

            </v-container>

          </v-card>
        </v-fade-transition>

        <!-- PDF viewer card -->
        <div id="pdfOuterContainer">
          <v-fade-transition>
            <v-card
                v-show="pdfLoaded"
                class="py-0"
                elevation="0"
                style="border-radius: 10px; background-color: #f4f8ff"
            >
              <v-card-title class="white rounded elevation-3 pdfTitle">
                {{ pdf.name }}
                <v-chip v-if="signedPDF" class="ml-2 white--text" color="green" label small>
                  <v-icon left small>mdi-certificate</v-icon>
                  <span>{{ $t('home.signed') }}</span>
                </v-chip>
                <v-spacer/>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon @click="onCloseClicked" v-on="on">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('home.instructions.stop') }}</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text>
                <pdf-view-container
                    v-if="inputFile != null"
                    ref="pdfViewContainer"
                    :file="inputFile"
                    :read-only="signStepper >= 4 || submittingFile || signedPDF"
                    @rendered="onPdfRendered"
                    @set="onSignatureBoxSet"
                ></pdf-view-container>
              </v-card-text>
            </v-card>
          </v-fade-transition>
        </div>

      </v-col>

    </v-row>

    <sign-pdf-dialog
        v-if="showSignPDFDialog"
        :creating-transaction="submittingFile"
        :pdf="pdf"
        :signed-content="signedPDF"
        :transaction-status="transactionStatus"
        @close="onCloseSigningDialogClicked"
        @restart="onConfirmSignatureClicked"
    ></sign-pdf-dialog>

  </v-container>

</template>

<script>
import PdfViewContainer from '@/components/PdfViewContainer'
import { SigningAPI } from '@/utils/signing'
import SignPdfDialog from '@/components/SignPdfDialog'
import { IdentityAPI } from '@/utils/identity'
import IdentityCard from '@/components/IdentityCard.vue'

const MAX_PDF_SIZE_IN_BYTES = 5 * 1000 * 1000

export default {
  name: 'DocumentPage',

  components: {
    IdentityCard,
    SignPdfDialog,
    PdfViewContainer
  },

  data: () => ({
    coordinatesSet: false,
    identity: {},
    inputFile: null,
    isSelectingFile: false,
    loaded: false,
    pdf: {
      content: null,
      name: '',
      signatureBox: {
        content: null,
        coordinates: {},
        page: 0,
      },
      transactionImage: null
    },
    pdfLoaded: false,
    signStepper: 1,
    signedPDF: null,
    showPDFSelector: true,
    showSignPDFDialog: false,
    submittingFile: false,
    transactionStatus: 0,
    transactionTimer: null,
  }),

  methods: {

    onBackClicked () {
      if (this.signStepper <= 1)
        return
      this.signStepper -= 1
      if (this.signStepper < 2)
        this.onCloseClicked()
      if (this.signStepper < 3) {
        this.$refs.pdfViewContainer.clearSignature()
      }
    },

    onCloseClicked () {
      this.pdfLoaded = false
      this.signStepper = 1
      this.pdf.transactionImage = null
      this.pdf.name = ''
      this.pdf.content = null
      this.pdf.signatureBox = {}
      this.inputFile = null
      this.signedPDF = null

      this.$refs.pdfViewContainer.clear()

      setTimeout(() => {
        this.showPDFSelector = true
      }, 800)
    },

    onCloseSigningDialogClicked () {
      clearInterval(this.timer)
      this.showSignPDFDialog = false
    },

    onDownloadClicked () {
      const downloadElement = document.createElement('a')
      downloadElement.setAttribute(
          'href',
          'data:application/octet-stream;base64,' + encodeURIComponent(this.signedPDF)
      )

      let strippedName = this.pdf.name.split('.pdf')[0]
      let signedPDFName = strippedName + '-signed.pdf'

      downloadElement.setAttribute('download', signedPDFName)
      downloadElement.style.display = 'none'

      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
    },

    onFileChanged (event) {
      let file = event.target.files[0]

      if (!file.type.includes('pdf')) {
        console.error('Please submit a PDF')
        return
      }

      if (file.size > MAX_PDF_SIZE_IN_BYTES) {
        console.error('Please submit a PDF with size smaller than 5 MB')
        return
      }

      this.pdf.name = file.name
      this.showPDF(file)
    },

    onSelectFileClicked () {
      this.isSelectingFile = true

      // When the FilePicker is closed, set isSelecting to false
      window.addEventListener('focus', () => {
        this.isSelectingFile = false
      }, { once: true })

      // Show picker of the hidden input field to select a file
      this.$refs.hiddenFileUploader.showPicker()
    },

    onPdfRendered () {
      setTimeout(() => {
        this.pdfLoaded = true
        this.signStepper = 2
      }, 800)
    },

    onSignatureBoxSet (coordinates, page, signatureImage, transactionImage) {
      this.pdf.signatureBox = {
        content: signatureImage,
        coordinates: coordinates,
        page: page
      }

      this.pdf.transactionImage = transactionImage
      this.signStepper = 3
    },

    checkSigningRequestStatus (request) {
      this.showSignDocumentDialog = true

      this.timer = setInterval(async () => {
        let result

        try {
          result = await SigningAPI.checkStatus(request.uuid)
        } catch (e) {
          this.transactionStatus = SigningAPI.STATUS_CODES.ERROR
          clearInterval(this.timer)
          return
        }
        this.transactionStatus = result.status

        switch (this.transactionStatus) {
          case SigningAPI.STATUS_CODES.SIGNED:
            this.signedPDF = result.signed_content
            if (this.$vuetify.breakpoint.smAndDown)
              this.showSignPDFDialog = false
            else setTimeout(() => this.showSignPDFDialog = false, 1500)
            clearInterval(this.timer)
            break
          case SigningAPI.STATUS_CODES.EXPIRED:
          case SigningAPI.STATUS_CODES.ERROR:
          case SigningAPI.STATUS_CODES.DENIED:
            clearInterval(this.timer)
            break
        }

      }, 2000)
    },

    async onConfirmSignatureClicked () {
      console.log('Confirm signature')
      clearInterval(this.timer)
      this.submittingFile = true

      this.transactionStatus = SigningAPI.STATUS_CODES.SUBMITTED

      try {
        let result = await SigningAPI.submit(this.pdf)
        this.showSignPDFDialog = true
        this.checkSigningRequestStatus(result)
      } catch (e) {
        console.error(e)
      }

      this.submittingFile = false
    },

    async showPDF (file) {
      this.showPDFSelector = false
      this.inputFile = file

      const reader = new FileReader()

      reader.onloadend = () => {
        this.pdf.content = reader.result.replace('data:', '').replace(/^.+,/, '')
      }

      reader.readAsDataURL(file)
    },

    async updateIdentity () {
      this.identity = IdentityAPI.identity
      this.loaded = true
    },

  },

  async created () {
    await this.updateIdentity()
  },

}
</script>

<style scoped>
.v-stepper--vertical {
  padding-bottom: 0;
}

.v-stepper--vertical .v-stepper__step {
  padding: 16px 0 8px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: justify;
}

.stepperTitle {
  color: #43474E;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 21.6px */
}

.dragAndDropMessage {
  color: #43474E;
  text-align: center;

  /* Body16/Bold */
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  margin-top: 30px;
  margin-bottom: 45px;
}

.browseFilesButton {
  position: relative;
  z-index: 2;
  color: #FFF;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 230px;
  padding: 7px;
}

.pdfTitle {
  position: sticky;
  top: 92px
}

.pdfTitle::before {
  background-color: #eaf1ff;
  width: 100%;
  height: 100vh;
  display: block;
  position: absolute;
  top: -100vh;
  left: 0;
  content: "";
}

.howToSignCard {
  position: sticky;
  top: 92px;
  z-index: 3
}

.small .howToSignCard {
  position: relative;
  top: 0;
}

.small .pdfTitle {
  position: relative;
  top: 0;
}

#pdfOuterContainer .v-card__text {
  overflow-x: auto;
  max-width: 100%;
}

.small #pdfOuterContainer .v-card__text {
  max-height: 80vh;
  overflow: auto;
}
</style>
<style>
body {
  font-family: Inter, sans-serif !important;
}

/*
.v-stepper__step > .v-stepper__step__step::before {
  content: "\2B22";
  font-size: 36px;
  position: absolute;
  z-index: -1;
  top: -2px;
  left: -2px;
  transition-duration: 300ms;
}

.v-stepper__step--active > .v-stepper__step__step::before {
  color: #2871b4;
}

.v-stepper__step--inactive > .v-stepper__step__step::before,
.v-stepper__step:not(.v-stepper__step--active) > .v-stepper__step__step::before {
  color: #9e9e9e;
}

.v-stepper > .v-stepper__step--active > .v-stepper__step__step,
.v-stepper > .v-stepper__step--inactive > .v-stepper__step__step,
.v-stepper > .v-stepper__step:not(.v-stepper__step--active) > .v-stepper__step__step {
  background-color: transparent !important;
  position: relative !important;
  z-index: 1 !important;
  margin: 10px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}*/
</style>