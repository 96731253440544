import axios from "axios";
import {StrongpinOAuth} from "@/utils/oauth";

let communicator = axios.create({
    baseURL: config.IDENTITY_API_URL,
    timeout: 5000,
    withCredentials: false,
});

export class IdentityAPI {

    static identity = null

    static async getIdentity() {
        let accessToken = StrongpinOAuth.accessToken;

        let headers = {
            "Authorization": "Bearer " + accessToken,
            "Content-Type": "Application/json"
        }

        let response;
        try {
            response = await communicator.get("user_info/", {headers: headers});
        } catch (e) {
            // Access Token expired
            if (e.response.status === 401) {
                await StrongpinOAuth.exchangeRefreshToken();
                headers = {
                    "Authorization": "Bearer " + StrongpinOAuth.accessToken,
                    "Content-Type": "Application/json"
                }
                response = await communicator.get("user_info/", {headers: headers});
            } else {
                throw new Error("Could not create get identity");
            }
        }

        if (response.status > 299) {
            // HANDLE ERROR
            throw "Could not receive identity information";
        }

        this.identity = response.data;
    }

}