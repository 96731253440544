<template>
    <svg version="1.1" id="pdfSignatureBox" xmlns="http://www.w3.org/2000/svg"
         height="50" width="260" viewBox="0 0 130 25" xml:space="preserve"
    >
        <text id="signatureName" lengthAdjust="spacingAndGlyphs"
              style="font-style: italic; font-family: 'Times New Roman', serif; font-size: 10px"
              x="0"
              y="0">
            <tspan dy="1em" x="2" y="0">{{ name }}</tspan>
        </text>
        <line x1="0" y1="14" x2="130" y2="14" style="stroke-width: 1" :style="'stroke: ' + $vuetify.theme.currentTheme.primary"/>
        <text x="0" y="21" id="signatureDate" style="font-family: 'Times New Roman', sans-serif; font-size: 6px;" :style="'fill: ' + $vuetify.theme.currentTheme.primary">
            {{ locationAndDate }}
        </text>
    </svg>
</template>

<script>
import { IdentityAPI } from '@/utils/identity'

export default {
    name: "SignatureBox",

    computed: {

        locationAndDate() {
            let now = new Date();
            return now.toUTCString();
        },

        name() {
            return IdentityAPI.identity.preferred_username;
        }
    },
  watch: {
    name: {
      handler: function (value) {
        this.$nextTick(
            () => {
              const nameLength = value.length
              const nameText = document.getElementById('signatureName').querySelector('tspan')
              if (nameLength > 25) {
                const fontSize = 6
                nameText.setAttribute('font-size', fontSize)
                nameText.setAttribute('dy', '1em')
              }
            }
        )
      }, immediate: true
    }
  }
}
</script>

<style lang="css">

</style>