const Utils = {

    generateRandom(length) {
        let random = new Uint8Array(length);
        window.crypto.getRandomValues(random);

        let regex = new RegExp("[^A-Za-z0-9]", "g");

        return this.ab2str(random).replace(regex, "");
    },

    async calcSha256(input) {
        let hash = await crypto.subtle.digest("SHA-256", this.str2ab(input));
        return this.ab2str(hash);
    },

    ab2str(ab) {
        return String.fromCharCode.apply(null, new Uint8Array(ab));
    },

    str2ab(str) {
        const buf = new ArrayBuffer(str.length);
        const bufView = new Uint8Array(buf);

        for (let i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }

        return buf;
    },

    b64ToBlob(b64Data, sliceSize = 512) {
        let contentType = 'image/png'

        let byteCharacters = atob(b64Data);
        let byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);
            let byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: contentType});
    },

}

export default Utils;