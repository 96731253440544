import * as pdfjs from 'pdfjs-dist/legacy/build/pdf.js'

const pdfjsWorker = require('pdfjs-dist/legacy/build/pdf.worker.entry');

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

// TODO: upgrade to non-legacy version (need non-optional chaining)
export const PDFUtility = {

    load(binaryPDF) {
        return new Promise((resolve, reject) => {
            let loadingTask = pdfjs.getDocument({data: binaryPDF});
            loadingTask.promise.then((pdf) => {
                console.log("PDF loaded")
                resolve(pdf);
            })
        });
    },

};
