<template>

  <v-dialog
      v-model="modal"
      max-width="600"
      persistent
      style="padding: 40px"
  >

    <v-card class="d-flex flex-column">

      <v-toolbar class="flex-grow-0" color="primary" dark flat>

        <v-toolbar-title class="font-weight-medium">
          <v-icon>mdi-cellphone-sound</v-icon>
          {{ $t('sign_dialog.header') }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="closeDialog" v-on="on">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('sign_dialog.close') }}</span>
        </v-tooltip>

      </v-toolbar>
      <v-container class="px-4 flex-grow-1">

        <v-row :class="$vuetify.breakpoint.smAndDown?'flex-column':''" class="fill-height" no-gutters
               style="justify-content: space-between">

          <v-col :class="$vuetify.breakpoint.smAndDown?'':'pr-4'" class="d-flex flex-column col-md-6 flex-grow-0">
            <template>
              <p>
                {{ $t('sign_dialog.push_message') }}
              </p>
              <p>
                {{ $t('sign_dialog.signing_as') }}
              </p>
              <IdentityCard/>
              <v-spacer/>
              <v-alert v-if="!$vuetify.breakpoint.smAndDown" class="pt-2 notReceivedPush" color="#001D36" dense text
                       type="info">
                {{ $t('sign_dialog.not_received_push') }}
              </v-alert>
            </template>
          </v-col>
          <v-col class="col-md-6 flex-grow-0">
            <v-container class="pa-0 pt-3 d-flex flex-column align-center">
              <span class="pdfName" style="margin-left: 1px">{{ pdf.name }}</span>
              <v-img
                  :src="imageBlob"
                  contain
                  height="auto"
                  max-width="270px"
                  style="border:solid #dddddd 1px; border-radius: 10px;"
              ></v-img>
            </v-container>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.smAndDown && $vuetify.breakpoint.mobile" class="mt-4 flex-grow-0 white"
                 style="position: sticky;bottom: 20px;">
            <v-btn color="primary" width="100%" @click="redirectToApp">
              {{ $t('sign_dialog.open_app') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-if="transactionStatus>1" class="mt-2" no-gutters>

          <v-row v-if="transactionStatus === 2" align="center" no-gutters>
            <v-alert class="mb-0" color="white" dense style="width: 100%; background-color: darkgreen !important;"
                     text
                     type="success">
              {{ $t('sign_dialog.signed_successfully') }}
            </v-alert>
          </v-row>

          <v-alert v-if="transactionStatus === 3" class="mb-0" dense text type="error" width="100%">
            {{ $t('sign_dialog.expired') }}
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn :disabled="creatingTransaction" color="red" icon small @click="restartTransaction"
                         v-on="on">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('common.try_again') }}</span>
              </v-tooltip>
            </template>
          </v-alert>

          <v-alert v-if="transactionStatus === 5" class="mb-0" dense text type="error" width="100%">
            {{ $t('sign_dialog.rejected') }}
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn :disabled="creatingTransaction" color="red" icon small @click="restartTransaction"
                         v-on="on">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('common.try_again') }}</span>
              </v-tooltip>
            </template>
          </v-alert>

          <v-alert v-if="transactionStatus === 4 || transactionStatus > 5" class="mb-0" dense text type="error"
                   width="100%">
            {{ $t('sign_dialog.failed') }}
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn :disabled="creatingTransaction" color="red" icon small @click="restartTransaction"
                         v-on="on">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('common.try_again') }}</span>
              </v-tooltip>
            </template>
          </v-alert>
        </v-row>

      </v-container>

      <v-progress-linear
          v-if="(transactionStatus === 0 || transactionStatus === 1) && !creatingTransaction"
          color="primary"
          indeterminate
          style="height: 8px; position: sticky;bottom: 0"
      ></v-progress-linear>

      <v-progress-linear v-if="creatingTransaction" indeterminate></v-progress-linear>

    </v-card>

  </v-dialog>

</template>

<script>
import Utils from '@/utils/utils'
import IdentityCard from '@/components/IdentityCard.vue'
import { IdentityAPI } from '@/utils/identity'

export default {
  name: 'SignPdfDialog',
  computed: {
    IdentityAPI () {
      return IdentityAPI
    }
  },
  components: { IdentityCard },

  props: {

    creatingTransaction: {
      type: Boolean,
      required: true
    },

    pdf: {
      type: Object,
      required: true
    },

    signedContent: {
      type: String,
      required: false
    },

    transactionStatus: {
      type: Number,
      required: true
    },

  },

  data: () => ({
    imageBlob: '',
    restartingTransaction: false,
    modal: true
  }),

  methods: {

    closeDialog () {
      this.$emit('close')
    },

    redirectToApp () {
      window.location.replace('ubiqu://authenticate/')
    },

    onDownloadClicked () {
      const downloadElement = document.createElement('a')
      downloadElement.setAttribute(
          'href',
          'data:application/octet-stream;base64,' + encodeURIComponent(this.signedContent)
      )

      let strippedName = this.pdf.name.split('.pdf')[0]
      let signedPDFName = strippedName + '-signed.pdf'

      downloadElement.setAttribute('download', signedPDFName)
      downloadElement.style.display = 'none'

      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
    },

    restartTransaction () {
      this.$emit('restart')
    },

  },

  created () {
    let blob = Utils.b64ToBlob(this.pdf.transactionImage)
    this.imageBlob = URL.createObjectURL(blob)
  }

}
</script>

<style scoped>
.pdfName {
  color: #43474E;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
}

.v-alert {
  /* Body14/Regular */
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

p {
  color: #43474E;

  /* Body16/regular */
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.notReceivedPush {
  margin-bottom: 1px;
  background-color: #b4cde5 !important;
  border-radius: 10px;
}

.notReceivedPush:before {
  background-color: transparent;
}
</style>