import axios from "axios";
import {StrongpinOAuth} from "@/utils/oauth";
import {IdentityAPI} from "@/utils/identity";

let transactionBackendCommunicator = axios.create({
    baseURL: config.TRANSACTION_API_URL,
    timeout: 15000,
    withCredentials: false
});

async function _post(path, data) {
    let headers = {
        "Authorization": "Bearer " + StrongpinOAuth.accessToken,
        "Content-Type": "Application/json"
    }

    let response;
    try {
        response = await transactionBackendCommunicator.post(path, data, {headers: headers});
    } catch (e) {
        // Access Token Expired
        // if (e.response.status === 401) {
        await StrongpinOAuth.exchangeRefreshToken();
        headers = {
            "Authorization": "Bearer " + StrongpinOAuth.accessToken,
            "Content-Type": "Application/json"
        }
        response = await transactionBackendCommunicator.post(path, data, {headers: headers});
        // } else {
        //     throw new Error("Could not create post operation");
        // }
    }

    if (response.status > 299) {
        // HANDLE ERROR
        throw new Error("Could not create post operation");
    }

    return response;
}

async function _get(path) {
    let headers = {
        "Authorization": "Bearer " + StrongpinOAuth.accessToken,
        "Content-Type": "Application/json"
    }

    let response;
    try {
        response = await transactionBackendCommunicator.get(path, {headers: headers});
    } catch (e) {
        // Access Token expired
        if (e.response.status === 401) {
            await StrongpinOAuth.exchangeRefreshToken();
            headers = {
                "Authorization": "Bearer " + StrongpinOAuth.accessToken,
                "Content-Type": "Application/json"
            }
            response = await transactionBackendCommunicator.get(path, {headers: headers});
        } else {
            throw new Error("Could not create post operation");
        }
    }

    if (response.status > 299) {
        // HANDLE ERROR
        throw new Error("Could not create post operation");
    }

    return response;
}

/**
 * Exposes all functionality of the signing API
 */
export class TransactionApi {

    static STATUS_CODES = {
        CREATED: 0,
        SUCCESS: 1,
        DENIED: 2,
        ERROR: 3,
        EXPIRED: 4,
    }

    static async checkStatus(requestUUID) {
        let getSignPath = "transaction/" + requestUUID + "/";

        let response = await _get(getSignPath);

        return response.data;
    }

    static async pay(title) {
        let fingerprint = "deadbeafdeadbeafdeadbeafdeadbeafdeadbeafdeadbeafdeadbeafdeadbeaf";

        let data = {
            subject: IdentityAPI.identity.sub,
            fingerprint: fingerprint,
            notification_data: {
                title: title
            },
        }

        let response = await _post("transaction/", data);

        return response.data;
    }

}