import Vue from 'vue';
import VueRouter from "vue-router";
import LoginPage from "@/views/LoginPage";
import DocumentPage from "@/views/DocumentPage";
import {StrongpinOAuth} from "@/utils/oauth";
import BankingPage from "@/views/BankingPage";

Vue.use(VueRouter);

const router = new VueRouter({
    routes: [
        {
            path: '/login',
            name: 'login',
            component: LoginPage,
        },
        {
            path: '/document',
            name: 'document',
            component: DocumentPage,
        },
        {
            path : '/document',
            name: 'bengkel',
            component: DocumentPage,
        },
        {
            path : '/document',
            name: 'survey',
            component: DocumentPage,
        },
        {
            path: '/banking',
            name: 'claim',
            component: BankingPage,
        },
        {
            path: '/banking',
            name: 'lelang',
            component: BankingPage,
        },
        {
            path: '/banking',
            name: 'banking',
            component: BankingPage,
        },
        {
            path: "/authorize",
        },
        {
            path: '*', redirect: {name: 'document'}
        },
    ],
    mode: 'history',
});

export default router;

router.beforeEach(async (to, from, next) => {

    // Authorization route
    if (to.path === "/authorize") {
        let authorizationCode = to.query["code"];
        let state = to.query["state"];

        StrongpinOAuth.handleAuthorizationResponse(authorizationCode, state)
            .then(() => {
                next({ name: "document", params: {} })
            })
            .catch((error) => {
                next({ name: "login", params: {} })
                console.log(error)
            })

        // Finish OAuth authorization
        return;
    }

    let authenticated = await StrongpinOAuth.isAuthenticated();

    // Authentication header guard
    if (to.name !== "login" && !authenticated) {
        next({ name: "login", params: {} })
    }

    if (to.name === "login" && authenticated) {
        next({ name: "document", params: {} })
    } else {
        next()
    }

});