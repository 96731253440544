import { render, staticRenderFns } from "./DocumentPage.vue?vue&type=template&id=64761bd8&scoped=true"
import script from "./DocumentPage.vue?vue&type=script&lang=js"
export * from "./DocumentPage.vue?vue&type=script&lang=js"
import style0 from "./DocumentPage.vue?vue&type=style&index=0&id=64761bd8&prod&scoped=true&lang=css"
import style1 from "./DocumentPage.vue?vue&type=style&index=1&id=64761bd8&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64761bd8",
  null
  
)

export default component.exports