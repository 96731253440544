import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueI18n from "vue-i18n"

import router from './router';
import i18n from "./plugins/i18n";

Vue.use(VueI18n);

Vue.config.productionTip = false;

new Vue({
    router,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount('#app');
