import axios from "axios";
import {StrongpinOAuth} from "@/utils/oauth";
import {IdentityAPI} from "@/utils/identity";

let signingBackendCommunicator = axios.create({
    baseURL: config.SIGNING_API_URL,
    timeout: 15000,
    withCredentials: false
});

async function _post(path, data) {
    let headers = {
        "Authorization": "Bearer " + StrongpinOAuth.accessToken,
        "Content-Type": "Application/json"
    }

    let response;
    try {
        response = await signingBackendCommunicator.post(path, data, {headers: headers});
    } catch (e) {
        // Access Token Expired
        // if (e.response.status === 401) {
            await StrongpinOAuth.exchangeRefreshToken();
            headers = {
                "Authorization": "Bearer " + StrongpinOAuth.accessToken,
                "Content-Type": "Application/json"
            }
            response = await signingBackendCommunicator.post(path, data, {headers: headers});
        // } else {
        //     throw new Error("Could not create post operation");
        // }
    }

    if (response.status > 299) {
        // HANDLE ERROR
        throw new Error("Could not create post operation");
    }

    return response;
}

async function _get(path) {
    let headers = {
        "Authorization": "Bearer " + StrongpinOAuth.accessToken,
        "Content-Type": "Application/json"
    }

    let response;
    try {
        response = await signingBackendCommunicator.get(path, {headers: headers});
    } catch (e) {
        // Access Token expired
        if (e.response.status === 401) {
            await StrongpinOAuth.exchangeRefreshToken();
            headers = {
                "Authorization": "Bearer " + StrongpinOAuth.accessToken,
                "Content-Type": "Application/json"
            }
            response = await signingBackendCommunicator.get(path, {headers: headers});
        } else {
            throw new Error("Could not create post operation");
        }
    }

    if (response.status > 299) {
        // HANDLE ERROR
        throw new Error("Could not create post operation");
    }

    return response;
}

/**
 * Exposes all functionality of the signing API
 */
export class SigningAPI {

    static STATUS_CODES = {
        SUBMITTED: 0,
        CONFIRMED: 1,
        SIGNED: 2,
        EXPIRED: 3,
        ERROR: 4,
        DENIED: 5
    }

    static TYPES = {
        PDF: 0,
    }

    static async submit(pdf) {
        let data = {
            "content": pdf.content,
            "document_name": pdf.name,
            "flow": config.SIGNING_API_FLOW_ID,
            "flow_type": 1,
            "signature_box": {
                "image": pdf.signatureBox.content,
                "image_width": 260,
                "image_height": 50,
                "image_x": pdf.signatureBox.coordinates.x,
                "image_y": pdf.signatureBox.coordinates.y,
                "image_page_index": pdf.signatureBox.page - 1, // Page is not indexed but human representation
            },
            "subject": IdentityAPI.identity.sub,
            "transaction_image": pdf.transactionImage,
            "type": SigningAPI.TYPES.PDF,
        }

        let response = await _post("pdf/", data);

        return response.data;
    }

    static async checkStatus(requestUUID) {
        let getSignPath = "pdf/" + requestUUID + "/";

        let response = await _get(getSignPath);

        return response.data;
    }

    static async downloadSignedPDF(identifier) {
        let getSignPath = "content/" + identifier + "/";

        let response = await _get(getSignPath);

        return response.data;
    }

}