<script>
import { IdentityAPI } from '../utils/identity'

export default {
  name: 'IdentityCard',
  computed: {
    IdentityAPI () {
      return IdentityAPI
    }
  },
}
</script>

<template>
  <v-sheet class="d-flex mb-4" elevation="2" style="border-radius: 10px">
    <v-img
        :src="require('../assets/ubiqu.svg')"
        class="mx-2"
        contain
    ></v-img>
    <v-container class="mx-2 mt-2">
      <v-row class="mb-1 name">{{ IdentityAPI.identity.preferred_username }}</v-row>
      <v-row>
        <span class="identityVerified">Identity Verified</span>
        <v-icon class="ml-1" color="green" style="position: relative; top: -5px">mdi-check</v-icon>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<style scoped>
.identityVerified {
  color: #43474E;

  /* Small/Regular */
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
}

.name {
  color: #43474E;

  /* Body16/regular */
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
</style>