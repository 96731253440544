import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

/**
 * Based upon: https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-vue-app-with-vue-i18n
 */
function loadLocaleMessages() {
    const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    return messages
}

const dateTimeFormats = {
    'en': {
        clock: {
            hour: 'numeric', minute: 'numeric',
        },
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            weekday: 'short', month: 'short', day: 'numeric',
            year: 'numeric', hour: 'numeric', minute: 'numeric'
        }
    }
}

export default new VueI18n({
    dateTimeFormats,
    locale: 'en',
    fallbackLocale: 'en',
    silentTranslationWarn: true,
    messages: loadLocaleMessages(),
})