<template>

  <v-app style="background-color: #eaf1ff !important;">

    <template v-if="isLoggedIn">

      <v-app-bar
          app
          color="primary"
          dark
          flat
      >

        <v-container class="py-0 fill-height px-0">

          <router-link class="d-flex text-decoration-none white--text" to="{name:'document'}">
            <v-img
                :src="require('./assets/' + providerImage)"
                class="mr-2"
                max-height="60px"
                max-width="140px"
                contain
            ></v-img>
            <span class="navbarTitle">Signing</span>
          </router-link>

          <!--                    <v-btn-->
          <!--                        v-for="link in links"-->
          <!--                        :color="link.route === $route.name ? 'primary lighten-2' : ''"-->
          <!--                        @click="openLink(link)"-->
          <!--                        :key="link.name"-->
          <!--                        text-->
          <!--                    >{{ link.name }}</v-btn>-->

          <v-spacer/>

          <v-menu
              v-if="identity"
              bottom
              nudge-bottom="20"

              nudge-right="10"
              offset-y
              open-on-click
              transition="slide-y-transition"
          >
            <template v-slot:activator="{ on }">
              <div class="align-center d-flex clickable" v-on="on">
                <v-avatar class="ml-4" color="white" size="36">

                                <span :style="'color:' + $vuetify.theme.currentTheme.primary">
                                    {{ identity.preferred_username.substring(0, 1).toUpperCase() }}
                                </span>

                </v-avatar>
              </div>
            </template>

            <v-card width="350">

              <!-- Active user -->
              <v-card-title class="justify-center pb-0">
                <v-avatar class="justify-center white--text text-h3" color="primary"
                          size="75">
                  {{ identity.preferred_username.substring(0, 1).toUpperCase() }}
                </v-avatar>
              </v-card-title>
              <v-card-title class="justify-center pb-0">
                {{ identity.preferred_username }}
              </v-card-title>
              <v-card-title v-if="identity.sub" class="justify-center text-overline pt-0">
                {{ identity.sub }}
              </v-card-title>
              <v-card-text class="text-center">
                <p>{{ identity.organisation.name }}</p>
                <v-btn outlined small @click="clickedOnLogout">
                  <v-icon small>mdi-logout</v-icon>
                  {{ $t('navbar.logout') }}
                </v-btn>
              </v-card-text>

              <v-divider></v-divider>

            </v-card>

          </v-menu>


        </v-container>

      </v-app-bar>

    </template>

    <v-main>
      <router-view style="height: 100%"></router-view>
    </v-main>

  </v-app>

</template>

<script>

import Vue from 'vue'
import { StrongpinOAuth } from './utils/oauth'
import { IdentityAPI } from '@/utils/identity'

export default {
  name: 'App',

  data: () => ({
    isLoggedIn: false,
    issuerName: '',
    links: [],
    providerImage: '',
  }),

  watch: {

    $route (to) {
      this.isLoggedIn = !['login'].includes(to.name)
    },

  },

  computed: {

    identity () {
      return IdentityAPI.identity
    }

  },

  methods: {
    applyConfiguration () {
      // Set routes
      if (this.issuerName === 'idigi') {
        this.links = [
          { name: 'Daftar Relawan', route: 'document' },
          { name: 'Bli Tiket', route: 'banking' }
        ]
      } else {
        this.links = [
          { name: 'Signing', route: 'document' }
        ]
      }

      // Set title and icons
      Vue.prototype.$applicationName = config.APPLICATION_TITLE
      document.title = config.APPLICATION_TITLE

      // Set language if applicable
      if (config.PREFERRED_LANGUAGE) {
        this.$i18n.locale = config.PREFERRED_LANGUAGE
      }

      // Set theming and images
      document.getElementById('favicon').href = config.BASE_URL + config.FAVICON_IMAGE
      this.providerImage = config.PROVIDER_IMAGE_WHITE
      this.$vuetify.theme.currentTheme.primary = config.PRIMARY_COLOR
    },

    clickedOnLogout () {
      StrongpinOAuth.clear()
      this.$router.push({ name: 'login' })
    },

    openLink (link) {
      if (this.$route.name !== link.route) {
        this.$router.replace({ name: link.route })
      }
    },
  },

  created () {
    this.issuerName = config.OAUTH_ISSUER_NAME
    this.applyConfiguration()
  },

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.v-navigation-drawer__border {
  display: none;
}

.clickable {
  cursor: pointer;
}

.navbarTitle {
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 45px */
}

</style>