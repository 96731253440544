<template>

    <v-container :class="{'grey lighten-2': !$vuetify.theme.dark}" fluid class="mx-auto mt-4">

        <!-- Login Card -->
        <v-row style="top: -105px; position: relative" justify="center" align="center">

            <v-col cols="auto" class="px-0">
                    <v-card elevation="0" class="pa-8 d-flex" dark
                            style="border: 1px solid black;
                            background-color: white;
                            justify-content: center"
                            >
                        <div class="d-flex flex-column">
                            <img :src="require('../../assets/demo-26-11-2023/LoginCard-left.png')" style="width: 250px"/>
                            <div class="black--text mt-4"> Daftar sebagai sukarelawan </div>
                        </div>

                    </v-card>

            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="auto" class="px-0">

                <v-fade-transition>

                    <v-card elevation="0" class="pa-4 d-flex" dark
                            style="border: 1px solid black;
                            background-color: #0076ba;
                            width: 200px;
                            justify-content: center"
                             @click="onLoginClicked"
                            >
                        <div class="d-flex flex-column">
                            <img :src="require('../../assets/idigi.svg')" style="height: 100px"/>
                            <div class="white--text mt-4"> Masuk disini </div>
                        </div>

                    </v-card>

                </v-fade-transition>

            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="auto" class="px-0">

                    <v-card elevation="0" class="pa-8 d-flex" dark
                            style="border: 1px solid black;
                            background-color: white;
                            justify-content: center"
                            >
                        <div class="d-flex flex-column">
                            <img :src="require('../../assets/demo-26-11-2023/LoginCard-right.png')" style="width: 250px"/>
                            <div class="black--text body-1 mt-4"> Beli tiket festival sekarang</div>
                        </div>

                    </v-card>
            </v-col>

        </v-row>

    </v-container>

</template>

<script>
export default {
    name: "IdigiLoginCard",

    props: {},

    data: () => ({
        imageHeight: 120,
        issuerImage: "",
        providerImage: "",
        providerName: "",
    }),

    methods: {

        onLoginClicked() {
            this.$emit("login");
        },

    },

    created() {
        this.issuerImage = config.OAUTH_ISSUER_LOGO;
        this.providerImage = "idigi-login-card.jpg"
        this.providerName = config.PROVIDER_NAME;
    },

}
</script>

<style scoped>
div {
    text-align: center;
}
</style>